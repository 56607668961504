<template>
  <v-app id="mestre">
    <v-navigation-drawer
      v-model="menu.exibido"
      v-bind="utilImp.cnt.CONFIG.tema.mestre.menuPrincipal.fundo"
      clipped
      app
    >
      <menu-principal v-bind:atualizar="menu.atualizar" />
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      v-bind="utilImp.cnt.CONFIG.tema.mestre.barraSuperior.fundo"
      app
    >
      <v-app-bar-nav-icon v-on:click.stop="exibirOcultarMenu()" />
      <v-toolbar-title
        >{{ mestre.title }} {{ mestre.proprietario }}</v-toolbar-title
      >
      <v-spacer />
      <icone-usuario v-if="mestre.exibirIconeUsuarioLogado" />
    </v-app-bar>

    <!-- <v-content class="quinary mx-2 mt-2 mb-2"> -->
    <v-main v-bind="utilImp.cnt.CONFIG.tema.mestre.detalhe.fundo">
      <router-view />
    </v-main>

    <v-footer
      v-bind:inset="mestre.inset"
      v-bind="utilImp.cnt.CONFIG.tema.mestre.rodape.fundo"
      app
    >
      <span>&copy; {{ mestre.copyright }}</span>
      <v-spacer></v-spacer>
      <span>{{ mestre.usuarioLogado }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import util from "../../util/Util.js";
import eventBus from "@/util/EventBus.js";
import MenuPrincipal from "@/views/sistema/menu/MenuPrincipal.vue";
import IconeUsuario from "@/views/proprietario/usuario/login/IconeUsuario.vue";

export default {
  components: {
    "menu-principal": MenuPrincipal,
    "icone-usuario": IconeUsuario
  },
  mixins: [util.mixUtil],
  data: () => ({
    utilImp: util,
    menu: { desbloqueado: false, exibido: false, atualizar: false },
    mestre: {
      title: "",
      copyright: "",
      inset: false,
      proprietario: "",
      usuarioLogado: "",
      exibirIconeUsuarioLogado: false
    }
  }),
  computed: {},
  created() {
    this.$vuetify.theme.dark = false;

    // Garantir que ao ser dado um refresh (F5) no navegador, o sistema seja direcionado para o login
    // Verificar se o componente para recadastrar senha foi chamado, devendo ter prioridade sobre o componente de login
    if (this.$route.params.usuarioRecadastrarSenhaToken === undefined) {
      this.$router.push({ name: "loginUsuario" }).catch(err => err); //catch necessario para evitar o erro NavigationDuplicated.
    }
    this.iniciar();
  },
  mounted() {
    // Metodo responsável por receber um eventbus do componente /usuario/login/LoginUsuario.vue, que autoriza a exibição e montagem do menu.
    // .bind(this) é necessário para que o eventBus possa acessar qualquer variável ou método do componente.
    eventBus.$on(
      util.cnt.EVENTBUS.menuPrincipal.exibir,
      function() {
        // Atualizar o menu baseado nas permissões do usuário
        this.menu.atualizar = true;
        // Desbloquear a gaveta do menu
        this.menu.desbloqueado = true;
        // Exibir a gaveta do menu
        this.menu.exibido = true;
        // Exibir icone do usuário logado
        this.mestre.exibirIconeUsuarioLogado = true;
        // Exibir usuário logado no rodape
        this.exibirRodapeUsuarioLogado();
        // Exibir proprietario
        this.exibirProprietario();
      }.bind(this)
    );

    eventBus.$on(
      util.cnt.EVENTBUS.menuPrincipal.ocultar,
      function() {
        // Exibir a gaveta do menu
        this.menu.exibido = false;
      }.bind(this)
    );
  },
  methods: {
    iniciar() {
      // util.fncStore.mestre.incluirGaveta(false);
      this.mestre.title = util.cnf.NOME_SERVICO;
      this.mestre.copyright = this.langParametro(
        "copyright",
        util.fnc.getYear()
      );
    },
    exibirOcultarMenu() {
      if (this.menu.desbloqueado) {
        this.menu.exibido = !this.menu.exibido;
      }
    },
    exibirRodapeUsuarioLogado() {
      let idProprietario = util.fncStore.usuarioLogado.obterIdProprietario();
      let proprietario =
        " - " + util.fncStore.usuarioLogado.obterProprietario();
      let unidade = " - " + util.fncStore.usuarioLogado.obterUnidade();
      let nomeCompleto = ": " + util.fncStore.usuarioLogado.obterNomeUsuario();
      if (proprietario === unidade) {
        unidade = "";
      }
      this.mestre.usuarioLogado =
        idProprietario + proprietario + unidade + nomeCompleto;
    },
    exibirProprietario() {
      this.mestre.proprietario =
        " - " + util.fncStore.usuarioLogado.obterProprietario();
    }
  }
};
</script>
