import i18n from "@/i18n/lang.js";

// Fuso Horário
var gmt = [
  { value: -2, text: i18n.t("gmtCuiaba") },
  { value: -3, text: i18n.t("gmtBrasilia") },
  { value: -4, text: i18n.t("gmtFernandoDeNoronha") }
];
function getGmt(key) {
  let index = this.gmt.findIndex(x => x.value === key);
  return this.gmt[index].text;
}

// Booleano
var booleano = [
  { value: "0", text: i18n.t("nao") },
  { value: "1", text: i18n.t("sim") }
];
function getBooleano(key) {
  return new Map(booleano.map(i => [i.value, i.text])).get(key);
}

export default {
  gmt,
  getGmt,
  booleano,
  getBooleano
};
