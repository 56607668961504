import moment from "moment";

/* Primeira letra do texto maiúscula e as demais minúsculas. */
function firstLetterTextUpperCase(text) {
  return (
    text
      .toLowerCase()
      .charAt(0)
      .toUpperCase() + text.toLowerCase().slice(1)
  );
}

/* Retorna o ano corrente. */
function getYear() {
  return new Date().getFullYear();
}

/* Data atual, tipo Date() */
function now() {
  return new Date();
}

/* Retorna data atual, tipo Date(), aumentada ou diminuida, em dias. */
function changeNow(day) {
  return new Date(now().getTime() + day * 24 * 60 * 60 * 1000);
}

/* Formata uma data, tipo Date() ou String, conforme a máscara informada.
 * Retorna uma String.
 */
function dateFormat(dateTime, mask) {
  return moment(dateTime).format(mask);
}

/* Retorna uma data, tipo Date(), com a hora 00:00:00 */
function firstDateTime(date) {
  return new Date(dateFormat(date, "YYYY-MM-DD") + " 00:00:00");
}

/* Retorna uma data, tipo Date(), com a hora 00:00:00 */
function firstMonthDateTime(date) {
  return new Date(dateFormat(date, "YYYY-MM") + "-01 00:00:00");
}

/* Converte Date() em milisegundos */
function dateToMillisecond(date) {
  return Date.parse(date);
}

/* Converte milisegundos em Date() */
function millisecondToDate(millisecond) {
  return new Date(millisecond);
}

/* Faz a montagem do header com o token de autenticação. */
function getHeaderAuthToken(token) {
  // return {headers: {'auth-token': token}}
  // {headers: {'content-type': 'application/json;charset=UTF-8', 'access-control-allow-origin': '*'}}
  return {
    headers: {
      "content-type": "application/json;charset=UTF-8",
      "access-control-allow-origin": "*",
      "auth-token": token
    }
  };
}

/* Faz a montagem do header com o token de autenticação para a geração de relatórios PDF no jasper. */
function getHeaderAuthTokenPdf(token) {
  return {
    headers: {
      "content-type": "application/pdf",
      "access-control-allow-origin": "*",
      "auth-token": token
    },
    responseType: "blob"
  };
}

/* Converte texto em letras maiúsculas */
function toLowerCase(text) {
  return text.toLowerCase().trim();
}

/* Excluir caracteres de uma String */
function replaceAll(text, find, replace) {
  return text.replace(new RegExp(find, "g"), replace);
}

/* Excluir pontos de uma String */
function replaceAllDots(text) {
  return text.replace(new RegExp(/[.]/, "g"), "");
}

function isNotNullNotEmpty(value) {
  let result = false;
  if (value !== null && value !== undefined && value !== "") {
    result = true;
  }
  return result;
}

/* Retorna "" caso a String seja nula ou indefinida */
function getValidString(text) {
  let result = "";
  if (text !== null && text !== undefined) {
    result = text;
  }
  return result;
}

/* 
  Método utilizado para verificar se um objeto json é (null, indefined, "") e obter o valor do atributo.)
  Retorna "" caso nao seja possível obter o valor
*/
function getObjectValue(object, attribute) {
  let value = "";
  // Verificar se o objeto é vazio
  if (object) {
    // Verificar se o atributo existe no objeto
    if (Object.prototype.hasOwnProperty.call(object, attribute)) {
      // Obter o valor do atributo
      value = object[attribute];
    }
  }
  return value != null ? value : "";
}

/* ### FUNCOES PERSONALIZADAS ### */

/* Verificar o dígito verificador do código de barras EAN13. */
function digitoVerificadorEan13(codigo) {
  let factor = 3;
  let sum = 0;
  let numlen = codigo.length;

  if (numlen == 13) {
    for (let index = codigo.length; index > 0; --index) {
      if (index != 13) {
        sum = sum + codigo.substring(index - 1, index) * factor;
        factor = 4 - factor;
      }
    }
    let cc = (1000 - sum) % 10;
    let ca = codigo.substring(12);
    if (cc == ca) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/* Formatar a saída do componente v-money para o padrão 123456789,00*/
function vmoneyDecimalFormat(number) {
  let result = 0;
  if (number !== null) {
    result = number.split(" ")[1].replace(new RegExp(/[.]/, "g"), "");
  }
  return result;
}
/* Obter a primeira datahora do dia em milisegundos. */
function getFirstDateTimeMilliseconds() {
  return this.dateToMillisecond(this.firstDateTime(this.now()));
}
/* Obter o primeiro dia do mês com a zero hora atribuida em milisegundos. */
function getFirstMonthDateTimeMilliseconds() {
  return this.dateToMillisecond(this.firstMonthDateTime(this.now()));
}
/* Obter a última datahora do dia, ou seja, a zero hora do dia de amanhã em milisegundos*/
function getLastDateTimeMilliseconds() {
  return this.dateToMillisecond(this.firstDateTime(this.changeNow(1)));
}

/* Avalia a força de uma senha retornando:
 * 0:Senha vazia, 1:Digitar mais caracteres, 2:Fraca, 3:Média, 4:Forte
 * Função copiada e utilizada em ForcaSenha.vue */
// function strengthPassword (pwd) {
//   var strongRegex = new RegExp('^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$', 'g')
//   var mediumRegex = new RegExp('^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$', 'g')
//   var enoughRegex = new RegExp('(?=.{6,}).*', 'g')
//   var result = ''
//   if (pwd.length === 0) {
//     result = 0 // Digite uma senha
//   } else if (enoughRegex.test(pwd) === false) {
//     result = 1 // Digite mais caracteres
//   } else if (strongRegex.test(pwd)) {
//     result = 4 // Forte
//   } else if (mediumRegex.test(pwd)) {
//     result = 3 // Médio
//   } else {
//     result = 2 // Fraco
//   }
//   return result
// }

// /* Aumenta ou diminui a data atual, em dias. */
// function changeNow(day) {
//   return (
//     moment()
//       .add(day, "days")
//       .toISOString()
//       .substr(0, 10) + " 00:00:00"
//   );
// }

// format: "DD/MM/YYYY HH:mm:ss", entrada "2019-02-22 13:54:12"
// setStringDate(date) {
//   // this.date = moment(date).toDate();
//   // ou
//   // Transformar em milissegundos
//   // var d = Date.parse(date);
//   // Transformar em data
//   // this.date = new Date(d);
// },

export default {
  firstLetterTextUpperCase,
  getYear,
  now,
  changeNow,
  dateFormat,
  firstDateTime,
  dateToMillisecond,
  millisecondToDate,
  getHeaderAuthToken,
  getHeaderAuthTokenPdf,
  toLowerCase,
  firstMonthDateTime,
  isNotNullNotEmpty,
  getValidString,
  getObjectValue,
  replaceAll,
  replaceAllDots,
  digitoVerificadorEan13,
  vmoneyDecimalFormat,
  getFirstDateTimeMilliseconds,
  getFirstMonthDateTimeMilliseconds,
  getLastDateTimeMilliseconds
};
