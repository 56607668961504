export default Object.freeze({
  P_PROPRIETARIO: [1001, 1002, 1003, 1004, 1005],
  P_1001: 1001, // permissao.proprietario.proprietario.listar
  P_1002: 1002, // permissao.proprietario.proprietario.incluir
  P_1003: 1003, // permissao.proprietario.proprietario.alterar
  P_1004: 1004, // permissao.proprietario.proprietario.excluir
  P_1005: 1005, // permissao.proprietario.proprietario.acao
  P_UNIDADE: [1006, 1007, 1008, 1009, 1010],
  P_1006: 1006, // permissao.proprietario.unidade.listar
  P_1007: 1007, // permissao.proprietario.unidade.incluir
  P_1008: 1008, // permissao.proprietario.unidade.alterar
  P_1009: 1009, // permissao.proprietario.unidade.excluir
  P_1010: 1010, // permissao.proprietario.unidade.acao
  P_PERFIL: [1011, 1012, 1013, 1014, 1015],
  P_1011: 1011, // permissao.proprietario.perfil.listar
  P_1012: 1012, // permissao.proprietario.perfil.incluir
  P_1013: 1013, // permissao.proprietario.perfil.alterar
  P_1014: 1014, // permissao.proprietario.perfil.excluir
  P_1015: 1015, // permissao.proprietario.perfil.acao
  P_USUARIO: [1016, 1017, 1018, 1019, 1020, 1021, 1022],
  P_1016: 1016, // permissao.proprietario.usuario.listar
  P_1017: 1017, // permissao.proprietario.usuario.incluir
  P_1018: 1018, // permissao.proprietario.usuario.alterar
  P_1019: 1019, // permissao.proprietario.usuario.excluir
  P_1020: 1020, // permissao.proprietario.usuario.acao
  P_1021: 1021, // permissao.proprietario.usuario.senha.alterar
  P_1022: 1022, // permissao.proprietario.usuario.email.enviar
  P_CONSOLE: [1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031],
  P_1023: 1023, // permissao.relatorio.console.listar
  P_1024: 1024, // permissao.relatorio.console.incluir
  P_1025: 1025, // permissao.relatorio.console.alterar
  P_1026: 1026, // permissao.relatorio.console.excluir
  P_1027: 1027, // permissao.relatorio.console.acao
  P_1028: 1028, // permissao.relatorio.console.executar
  P_1029: 1029, // permissao.relatorio.console.visualizar
  P_1030: 1030, // permissao.relatorio.console.compartilhar
  P_1031: 1031, // permissao.relatorio.console.clonar
  P_LOG_UTILIZACAO: [1032],
  P_1032: 1032, // permissao.sistema.logutilizacao
  P_CONFIGURACAO: [1033],
  P_1033: 1033 // permissao.sistema.configuracao.manipular
});
