<template>
  <div>
    <mestre />
  </div>
</template>

<script>
import Mestre from "@/views/layout/Mestre.vue";

export default {
  components: {
    mestre: Mestre
  }
};
</script>
