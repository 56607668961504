import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify/vuetify.js";
import i18n from "@/plugins/vue-i18n.js";
import "@/plugins/vee-validate.js";
import "@/plugins/vue-sweetalert2.js";
import "@/plugins/vuetify-datetime.js";
import "@/plugins/vuetify-number.js";
import "@/plugins/vue-loading-overlay.js";
import "@/plugins/vue-shortkey.js";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
