import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

export default {
  listarUnidade: (unidade, pagina, limite) => {
    return http.get(
      "/proprietario/unidade/listar_unidade/" +
        unidade +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  incluirUnidade: item => {
    return http.post(
      "/proprietario/unidade/incluir_unidade",
      item,
      util.getHeader()
    );
  },
  alterarUnidade: item => {
    return http.put(
      "/proprietario/unidade/alterar_unidade",
      item,
      util.getHeader()
    );
  },
  excluirUnidade: idUnidade => {
    return http.delete(
      "/proprietario/unidade/excluir_unidade/" + idUnidade,
      util.getHeader()
    );
  },
  verificarHorario: idUnidade => {
    return http.get(
      "/proprietario/unidade/verificar_horario/" + idUnidade,
      util.getHeader()
    );
  },
  // Listar todas as unidades de um proprietário
  listarUnidades: function() {
    return http.get(
      "/proprietario/unidade/listar_unidades/",
      util.getHeader()
    );
  }
};
