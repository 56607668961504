import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

export default {
  listarConsole: (console, pagina, limite) => {
    return http.get(
      "/consulta/console/listar_console/" +
        console +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  incluirConsole: item => {
    return http.post(
      "/consulta/console/incluir_console",
      item,
      util.getHeader()
    );
  },
  alterarConsole: item => {
    return http.put(
      "/consulta/console/alterar_console",
      item,
      util.getHeader()
    );
  },
  excluirConsole: idConsole => {
    return http.delete(
      "/consulta/console/excluir_console/" + idConsole,
      util.getHeader()
    );
  },
  compartilharConsole: (idConsole, item) => {
    return http.post(
      "/consulta/console/compartilhar_console/" + idConsole,
      item,
      util.getHeader()
    );
  },
  listarUsuarioParaCompartilhamento: idConsole => {
    return http.get(
      "/consulta/console/listar_usuario_para_compartilhamento/" + idConsole,
      util.getHeader()
    );
  },
  listarDadosConsole: (idConsole, parametro, pagina, limite) => {
    return http.get(
      "/consulta/console/listar_dados_console/" +
        idConsole +
        "/" +
        parametro +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  clonarConsole: idConsole => {
    return http.post(
      "/consulta/console/clonar_console/" + idConsole,
      null,
      util.getHeader()
    );
  }
};
