<template>
  <div>
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on }">
        <v-icon
          large
          class="mr-2"
          v-on="on"
          v-bind:style="{ cursor: 'pointer' }"
          v-bind:title="ultimoAcesso()"
          v-bind:disabled="desabilitado"
          >{{ utilImp.cnt.CONFIG.icon.pessoa }}</v-icon
        >
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuAcaoExtra"
          :key="index"
          v-on:click.stop.prevent="acaoExtra(item.value)"
          v-bind:disabled="desabilitado"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-label>{{
      utilImp.fncStore.usuarioLogado.obterPrimeiroNomeUsuario()
    }}</v-label>
    <!-- Componentes -->
    <AlterarSenha ref="refAlterarSenha" />
  </div>
</template>

<script>
import util from "../../../../util/Util.js";
import AlterarSenha from "@/views/proprietario/usuario/AlterarSenha.vue";

const ACAO_EXTRA_ALTERAR_SENHA = "acaoExtraAlterarSenha";
const ACAO_EXTRA_SAIR = "acaoExtraSair";

export default {
  components: { AlterarSenha },
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    utilImp: util,
    desabilitado: false,
    menuAcaoExtra: [],
  }),
  created() {
    this.iniciar();
  },
  mounted() {},
  methods: {
    iniciar() {
      // Itens do menu de opção extra
      // this.menuAcaoExtra.push({
      //   title: this.lang("alterarSenha"),
      //   value: ACAO_EXTRA_ALTERAR_SENHA,
      // });
      this.menuAcaoExtra.push({
        title: this.lang("sair"),
        value: ACAO_EXTRA_SAIR,
      });
    },
    acaoExtra(valor) {
      if (valor === ACAO_EXTRA_ALTERAR_SENHA) {
        this.alterarSenha();
      }
      if (valor === ACAO_EXTRA_SAIR) {
        this.modoSair();
      }
    },
    alterarSenha() {
      this.$refs.refAlterarSenha.exibir(
        util.fncStore.usuarioLogado.obterIdUsuario()
      );
    },
    modoSair() {
      this.confirm(this.lang("sair"), this.lang("desejaSair")).then(
        (result) => {
          if (result.value) {
            this.sair();
          }
        }
      );
    },
    ultimoAcesso: function() {
      return (
        this.lang("ultimoAcesso") +
        ": " +
        this.formatarDataHora(util.fncStore.usuarioLogado.dataUltimoAcesso())
      );
    },
    sair() {
      this.$router.go();
    },
  },
};
</script>
