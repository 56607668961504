import fnc from "./Function.js";
import cnf from "../config/Config.js";
import cnt from "./Constant.js";
import prm from "../config/Permission.js";
import srv from "../services/Service.js";
import val from "./Validation.js";
import store from "../store/index.js";
import fncStore from "../store/Function.js";
import arrayData from "./ArrayData.js";
import mapData from "./MapData.js";
import { mixUtil } from "../mixins/MixUtil.js";
import { mixAlerta } from "../mixins/MixAlerta.js";

function getHeader() {
  return fnc.getHeaderAuthToken(fncStore.usuarioLogado.obterToken());
}

// Header para a geração de relatórios jasper.
function getHeaderPdf() {
  return fnc.getHeaderAuthTokenPdf(fncStore.usuarioLogado.obterToken());
}

export default {
  getHeader,
  getHeaderPdf,
  fnc,
  cnf,
  cnt,
  prm,
  srv,
  val,
  store,
  fncStore,
  arrayData,
  mapData,
  mixUtil,
  mixAlerta
};
// store: um arquivo js pode acessar toda a store - util.store.getters['proprietarioUsuarioLogin/obterPermissao']
// fncStore: um arquivo js pode acessar funcoes especificas da store
