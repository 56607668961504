// Para alterar o idioma da validação em tempo de execução
// import { localize } from "vee-validate";
// localize('pt');

import { extend, localize } from "vee-validate";
import { required, min, max, email } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import pt from "vee-validate/dist/locale/pt_BR.json";

// Install rules
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);

// Install messages
localize({
  en,
  pt
});
