import Vue from "vue";
import VueRouter from "vue-router";
import LoginUsuario from "@/views/proprietario/usuario/login/LoginUsuario.vue";
import RecadastrarSenha from "@/views/proprietario/usuario/RecadastrarSenha.vue";

Vue.use(VueRouter);

const routes = [
  {
    // Login
    path: "/",
    name: "loginUsuario",
    component: LoginUsuario
  },
  {
    // Recadastrar senha usuário
    path:
      "/proprietario/usuario/recadastrar_senha/:usuarioRecadastrarSenhaToken",
    name: "proprietarioUsuarioRecadastrarSenha",
    component: RecadastrarSenha
  },
  {
    // Inicio - Componente inicial vazio exibido após o login
    path: "/sistema/inicio",
    name: "sistemaInicio",
    component: () => import("@/views/rastreamento/Rastreador.vue")
  },
  {
    // Proprietario
    path: "/proprietario/proprietario",
    name: "ProprietarioProprietario",
    component: () => import("@/views/proprietario/Proprietario.vue")
  },
  {
    // Unidade
    path: "/proprietario/unidade/unidade",
    name: "ProprietarioUnidadeUnidade",
    component: () => import("@/views/proprietario/unidade/Unidade.vue")
  },
  {
    // Perfil
    path: "/proprietario/usuario/perfil/perfil",
    name: "ProprietarioUsuarioPerfilPerfil",
    component: () => import("@/views/proprietario/usuario/perfil/Perfil.vue")
  },
  {
    // Usuário
    path: "/proprietario/usuario/usuario",
    name: "ProprietarioUsuarioUsuario",
    component: () => import("@/views/proprietario/usuario/Usuario.vue")
  },
  {
    // Console
    path: "/relatorio/console/console",
    name: "RelatorioConsoleConsole",
    component: () => import("@/views/relatorio/console/Console.vue")
  },
  {
    // Log de Utilização
    path: "/sistema/LogUtilizacao",
    name: "SistemaLogUtilizacao",
    component: () => import("@/views/sistema/logutilizacao/LogUtilizacao.vue")
  },
  {
    // Configuração
    path: "/sistema/Configuracao",
    name: "SistemaConfiguracao",
    component: () => import("@/views/sistema/Configuracao.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
