// Para alterar o idioma do i18n em tempo de execução: this.$i18n.locale = 'ar'
// Para receber o valor: alert(this.$i18n.t('informeEmail'))

import Vue from "vue";
import VueI18n from "vue-i18n";
import pt from "./pt.json";
import en from "./en.json";

Vue.use(VueI18n);

const locale = "pt";

const messages = {
  pt,
  en
};

const i18n = new VueI18n({
  locale,
  messages
});

export default i18n;
