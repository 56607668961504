import cnt from "./Constant.js";
import i18n from "@/i18n/lang.js";

// utilImp.mapData.mapResultCode.get("sucesso")
const mapResultCode = new Map();
mapResultCode.set(cnt.SERVICO.mensagem.sucesso, i18n.t("sucesso"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.servidor, i18n.t("erroServidor"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.servico, i18n.t("erroServico"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.operacao, i18n.t("erroOperacao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.validacao, i18n.t("erroValidacao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.duplicacao, i18n.t("erroDuplicao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.inclusao, i18n.t("erroInclusao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.alteracao, i18n.t("erroAlteracao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.exclusao, i18n.t("erroExclusao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.inativo, i18n.t("erroInativo"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.inexistente, i18n.t("erroInexistente"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.expirado, i18n.t("erroExpirado"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.permissao, i18n.t("erroPermissao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.autenticacao, i18n.t("erroAutenticacao"));
mapResultCode.set(cnt.SERVICO.mensagem.erro.login, i18n.t("erroLogin"));

export default {
  mapResultCode
};

// var mapObj = new Map()
// mapObj.set('key', 'value')
// mapObj.get('key')
// mapObj.delete('key')
