import i18n from "@/i18n/lang.js";

export default Object.freeze({
  GMT_PADRAO: -3,
  COR_PADRAO: "blue darken-2", // #808080,  grey darken-2  blue darken-2

  CAMPO: {
    tamanho: {
      maximo: {},
      minimo: {
        senha: 6
      }
    },
    obrigatorio: " *"
  },

  BOLEANO: {
    sim: "1",
    nao: "0",
    true: true,
    false: false
  },

  TECLA: {
    enter: "Enter",
    esc: "Esc"
  },

  SERVICO: {
    mensagem: {
      sucesso: 1,
      erro: {
        servidor: 2,
        servico: 3,
        operacao: 4,
        validacao: 5,
        duplicacao: 6,
        inclusao: 7,
        alteracao: 8,
        exclusao: 9,
        inativo: 10,
        inexistente: 11,
        expirado: 12,
        permissao: 13,
        autenticacao: 14,
        login: 15
      }
    }
  },

  ARRAY: {
    itemPagina1: {
      qtd: [
        "10",
        "20",
        "30",
        "40",
        "50",
        "100",
        "150",
        "200",
        "250",
        "300",
        "400",
        "500",
        "800",
        "1000"
      ],
      posicao: {
        p1: 5,
        p2: 6,
        p3: 7,
        p4: 8,
        p5: 9,
        p6: 10,
        p7: 11,
        p8: 12,
        p9: 13,
        p10: 14
      }
    }
  },

  EVENTBUS: {
    menuPrincipal: {
      exibir: "eventBusMenuPrincipalExibir",
      ocultar: "eventBusMenuPrincipalOcultar"
    },
    veiculo: {
      localizar: "eventBusVeiculo"
    }
  },

  CONFIG: {
    btn: {
      incluir: {
        icone: "mdi-plus",
        atalho: ["ctrl", "alt", "4"],
        // Bind
        color: "primary",
        title: "ctrl + alt + 4",
        class: "mb-0 white--text"
      },
      salvar: {
        icone: "mdi-content-save",
        atalho: ["ctrl", "alt", "5"],
        // Bind
        color: "success",
        title: "ctrl + alt + 5",
        class: "mb-0 white--text"
      },
      cancelar: {
        icone: "mdi-close",
        // Bind
        color: "warning",
        title: i18n.t("escParaCancelar"),
        class: "mb-0 white--text"
      },
      limpar: {
        icone: "mdi-close",
        atalho: ["ctrl", "alt", "0"],
        // Bind
        color: "tertiary white--text",
        title: i18n.t("limpar"),
        class: "mb-0 white--text"
      },
      pesquisar: {
        icone: "mdi-file-find",
        atalho: ["ctrl", "alt", "1"],
        // Bind
        color: "primary",
        title: "ctrl + alt + 1",
        class: "mb-0 white--text"
      },
      confirmar: {
        icone: "mdi-check",
        atalho: ["ctrl", "alt", "2"],
        // Bind
        color: "success",
        title: "ctrl + alt + 2",
        class: "mb-0 white--text"
      },
      executar: {
        icone: "mdi-check",
        atalho: ["ctrl", "alt", "2"],
        // Bind
        color: "success",
        title: "ctrl + alt + 2",
        class: "mb-0 white--text"
      },
      enviar: {
        icone: "mdi-check",
        // Bind
        color: "success",
        title: i18n.t("enviar"),
        class: "mb-0 white--text"
      },
      proximo: {
        icone: "mdi-chevron-right",
        // Bind
        color: "primary",
        title: i18n.t("proxima"),
        class: "mb-0 white--text"
      },
      voltar: {
        icone: "mdi-chevron-left",
        // Bind
        color: "primary",
        title: i18n.t("voltar"),
        class: "mb-0 white--text"
      }
    },
    checkbox: {
      // Bind
      color: "primary"
    },
    select: {
      // Bind
      outlined: true,
      backgroundColor: "temaSelectFundoCor"
    },
    textfield: {
      // Bind
      outlined: true,
      backgroundColor: "temaTextfieldFundoCor"
    },
    textarea: {
      // Bind
      outlined: true,
      backgroundColor: "temaTextareaFundoCor"
    },
    chip: {
      cor: {
        cinza: {
          // Bind
          dark: true,
          color: "grey ligth"
        }
      }
    },
    icon: {
      cor: {
        branco: {
          // Bind
          dark: true,
          color: "white"
        },
        cinza: {
          // Bind
          dark: true,
          color: "grey"
        }
      },
      tamanho: {
        pequeno: "mdi-18px",
        medio: "mdi-24px",
        grande: "mdi-36px",
        extraGrande: "mdi-48px"
      },
      sim: "mdi-check-box-outline",
      nao: "mdi-checkbox-blank-outline",
      alterar: "mdi-pencil-outline",
      cancelar: "mdi-close-circle-outline",
      excluir: "mdi-delete-outline",
      localizar: "search",
      acaoExtra: "mdi-apps",
      pessoa: "mdi-account",
      rodar: "mdi-play-box-outline",
      fechar: "mdi-close",
      telefone: "mdi-phone",
      email: "mdi-email",
      senha: "mdi-lock",
      chave: "mdi-key",
      marcador: "mdi-square-medium" // :: Marcador de tópicos
    },
    guia: {
      // Bind
      dark: true, // Indica fundo escuro
      class: "elevation-0",
      backgroundColor: "primary"
    },
    timer: {
      focus: 500,
      refresh: 500
    },
    vuetify: {
      simpleDate1: {
        format: "DD/MM/YYYY",
        clearable: true,
        readonly: false,
        outlined: true,
        icon: "mdi-calendar",
        errorMessage: i18n.t("dataInvalida")
      },
      simpleDate2: {
        format: "DD/MM/YYYY",
        clearable: true,
        readonly: false,
        outlined: true,
        icon: "",
        errorMessage: i18n.t("dataInvalida")
      },
      dateTime1: {
        tabDateTitle: i18n.t("data"),
        tabTimeTitle: i18n.t("hora"),
        locale: i18n.t("locale"),
        format: i18n.t("formatoData"),
        icon: "",
        iconTime: "mdi-av-timer",
        titleBarColor: "primary",
        backgroundColor: "temaTextfieldFundoCor",
        closeOnDateClick: true,
        useSeconds: false,
        clearable: false,
        outlined: true
      }
    },

    tema: {
      mestre: {
        barraSuperior: {
          fundo: {
            // Bind
            dark: true, // Cor do icone e titulo
            color: "temaMestreBarraSuperiorFundoCor"
          }
        },
        menuPrincipal: {
          fundo: {
            // Bind
            color: "temaMestreMenuPrincipalFundoCor"
          }
        },
        detalhe: {
          fundo: {
            // Bind
            class: "temaMestreMenuPrincipalFundoCor"
          }
        },
        rodape: {
          fundo: {
            // Bind
            dark: true,
            color: "temaMestreRodapeFundoCor"
          }
        }
      },
      detalhe: {
        barraSuperior: {
          fundo: {
            // Bind
            color: "temaDetalheBarraSuperiorFundoCor",
            class: "elevation-0"
          },
          icone: {
            nome: "mdi-square-medium",
            // Bind
            color: "temaDetalheBarraSuperiorIconeCor"
          },
          titulo: {
            // Bind
            class: "font-weight-bold primary--text"
          }
        }
      },
      janela: {
        barraSuperior: {
          // Bind
          class: "temaJanelaBarraSuperiorCor white--text"
        },
        rodape: {
          // Bind
          class: "temaJanelaRodapeCor"
        },
        icone: {
          // Bind
          color: "white",
          title: i18n.t("escParaCancelar")
        },
        tamanho: {
          largura: {
            maxima: {
              filtro: "50%", // DIALOG_FILTRO_MAX_LARGURA_PADRAO
              salvar: "60%", // DIALOG_SALVAR_MAX_LARGURA_PADRAO
              padrao: "80%", // DIALOG_PADRAO_MAX_LARGURA_PADRAO
              pequeno: "30%"
            }
          }
        }
      }
    }
  }
});
