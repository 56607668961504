<template>
  <div>
    <v-dialog
      scrollable
      persistent
      max-width="600px"
      v-model="exibirDialogo"
      v-bind:disabled="desabilitado"
    >
      <v-card>
        <v-card-title
          primary-title
          v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior"
        >
          <span class="headline">{{ lang("informeNovaSenha") }}</span>
          <v-divider class="mx-2" inset horizontal></v-divider>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  name="namNovaSenha"
                  ref="refNovaSenha"
                  type="password"
                  v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.senha"
                  v-model="modNovaSenha"
                  v-bind:label="lang('novaSenha')"
                  v-on:keyup="keyUpNovaSenha"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  name="namConfirmarNovaSenha"
                  ref="refConfirmarNovaSenha"
                  type="password"
                  v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.senha"
                  v-model="modConfirmarNovaSenha"
                  v-bind:label="lang('confirmarNovaSenha')"
                  v-on:keyup="keyUpConfirmarNovaSenha"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <ForcaSenha ref="refForcaSenha" />
          <v-spacer />
          <v-btn v-on:click="validar()" v-bind="utilImp.cnt.CONFIG.btn.enviar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.enviar.icone }}</v-icon
            >{{ lang("enviar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import ForcaSenha from "@/views/proprietario/usuario/ForcaSenha.vue";

export default {
  components: { ForcaSenha },
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    utilImp: util,
    modNovaSenha: "",
    modConfirmarNovaSenha: "",
    regraValidacao: "required|min:" + util.cnt.CAMPO.tamanho.minimo.senha,
    habilitarBotao: true,
    desabilitado: false,
    exibirDialogo: true,
    propForcaSenha: { color: "", text: "", confirmed: false },
    idUsuario: 0
  }),
  mounted() {
    this.setFocusNovaSenha();
  },
  methods: {
    async validar() {
      let result = "";
      result = await util.val.validate(this.modNovaSenha, this.regraValidacao);
      if (result.valid) {
        if (this.modNovaSenha === this.modConfirmarNovaSenha) {
          if (this.validarForcaSenha(this.modNovaSenha) === true) {
            this.recadastrarSenha();
          } else {
            this.alertWarningToast(
              this.lang("senhaInvalida") + ". " + this.lang("digiteSenhaValida")
            );
          }
        } else {
          this.alertWarningToast(this.langCamposIguais());
        }
      } else {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("novaSenha"))
        );
      }
    },
    recadastrarSenha() {
      this.habilitar(false);
      util.srv.usuario
        .recadastrarSenha(this.obterRouterToken() + "/" + this.modNovaSenha)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.distribuir(response.data);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.habilitar(true);
        });
    },
    distribuir(registro) {
      if (registro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        this.alertSucess(this.langResultCode(registro.resultCode));
        this.sair();
      } else {
        this.alertError(this.langResultCode(registro.resultCode));
      }
    },
    setFocusNovaSenha() {
      setTimeout(() => {
        this.$refs.refNovaSenha.focus();
      }, util.cnt.CONFIG.timer.focus);
    },
    keyUpNovaSenha: function(event) {
      this.$refs.refForcaSenha.verificarForcaSenha(this.modNovaSenha);
      var k = event.key;
      if (k === util.cnt.TECLA.enter) {
        this.$refs.refConfirmarNovaSenha.focus();
      }
    },
    keyUpConfirmarNovaSenha: function(event) {
      var k = event.key;
      if (k === util.cnt.TECLA.enter) {
        this.validar();
      }
    },
    langCamposIguais: function() {
      let parameter = "camposDevemSerIguais";
      let item1 = this.lang("novaSenha");
      let item2 = this.lang("confirmarNovaSenha");
      return this.$i18n.t(parameter, 10, { 1: item1, 2: item2 });
    },
    habilitar(habilitar) {
      this.habilitarBotao = habilitar;
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    // Obter do router o token enviado via url
    obterRouterToken: function() {
      return this.$route.params.usuarioRecadastrarSenhaToken;
    },
    validarForcaSenha: function(senha) {
      return this.$refs.refForcaSenha.validarForcaSenha(senha);
    },
    sair() {
      setTimeout(() => {
        // this.$router.go();
        this.$router.push("/");
      }, 3000);
    }
  }
};
</script>
