<template>
  <div>
    <v-spacer />
    <v-text-field
      v-model="placa"
      label="Placa ou Frota"
      v-on:keyup="timerListar()"
      outlined
    />
    <v-list>
      <span v-for="item in itens" v-bind:key="item.placa">
        <!-- Menu -->
        <v-list-group no-action>
          <v-list-item slot="activator">
            <v-list-item-content>
              <v-list-item-title v-on:click="rastrear(item)">{{
                item.placa + " - " + item.frota
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </span>
    </v-list>
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import eventBus from "@/util/EventBus.js";

export default {
  mixins: [util.mixUtil],
  props: {
    atualizar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timerUpdate: null,
    utilImp: util,
    itens: [],
    placa: "",
  }),
  computed: {},

  watch: {
    atualizar() {
      this.limpar();
      if (this.atualizar) {
        this.listar();
      }
    },
  },

  created() {},

  mounted() {},

  // Concatenar array: array1.concat(array2, array3, ..., arrayX)
  methods: {
    iniciar() {
      this.itens = [
        // Proprietario
        {
          icone: "mdi-star-box",
          titulo: this.lang("proprietario"),
          permissao: true,
          subItens: [
            {
              icone: "mdi-forwardburger",
              titulo: this.lang("proprietario"),
              permissao: true,
              router: "ProprietarioProprietario",
            },
          ],
        },
        // Unidade
        // {
        //   icone: "mdi-bank",
        //   titulo: this.lang("unidade"),
        //   permissao: this.arrayPermissao(util.prm.P_UNIDADE),
        //   subItens: [
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("unidades"),
        //       permissao: this.permissao(util.prm.P_1006),
        //       router: "ProprietarioUnidadeUnidade"
        //     }
        //   ]
        // },
        // Usuario
        // {
        //   icone: "mdi-account-multiple",
        //   titulo: this.lang("usuario"),
        //   permissao: this.arrayPermissao(
        //     util.prm.P_PERFIL.concat(util.prm.P_USUARIO)
        //   ),
        //   subItens: [
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("perfil"),
        //       permissao: this.permissao(util.prm.P_1011),
        //       router: "ProprietarioUsuarioPerfilPerfil"
        //     },
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("usuarios"),
        //       permissao: this.permissao(util.prm.P_1016),
        //       router: "ProprietarioUsuarioUsuario"
        //     }
        //   ]
        // },
        // Relatorio
        // {
        //   icone: "mdi-file-document-outline",
        //   titulo: this.lang("relatorio"),
        //   permissao: this.arrayPermissao(util.prm.P_CONSOLE),
        //   subItens: [
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("console"),
        //       permissao: this.permissao(util.prm.P_1023),
        //       router: "RelatorioConsoleConsole"
        //     }
        //   ]
        // },
        // Sistema
        // {
        //   icone: "mdi-laptop",
        //   titulo: this.lang("sistema"),
        //   permissao: this.arrayPermissao(
        //     util.prm.P_LOG_UTILIZACAO.concat(util.prm.P_CONFIGURACAO)
        //   ),
        //   subItens: [
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("logUtilizacao"),
        //       permissao: this.permissao(util.prm.P_1032),
        //       router: "SistemaLogUtilizacao"
        //     },
        //     {
        //       icone: "mdi-forwardburger",
        //       titulo: this.lang("configuracao"),
        //       permissao: this.permissao(util.prm.P_1033),
        //       router: "SistemaConfiguracao"
        //     }
        //   ]
        // }
      ];
      this.desarmar();
    },
    
    timerListar() {
      // Debounce
      clearTimeout(this.timerUpdate);
      this.timerUpdate = setTimeout(() => {
        this.listar();
      }, 1000);
    },

    listar() {
      // this.desabilitar(true);
      // this.limparLista();
      // this.showLoader();
      // let ativo = this.utilImp.cnt.NULL;
      util.srv.veiculo
        .listarVeiculo(this.placa)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            if (response.data.result !== null) {
              this.itens = response.data.result;
            } else {
              // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
            }
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          // this.desabilitar(false);
          // this.fecharJanelaPesquisar();
          // this.hideLoader();
        });
    },

    rastrear(item) {
      eventBus.$emit(
        util.cnt.EVENTBUS.veiculo.localizar,
        item.idVeiculo,
        item.placa
      );
      eventBus.$emit(util.cnt.EVENTBUS.menuPrincipal.ocultar);
    },

    limpar() {
      this.itens = [];
    },
    /* Desarmar a acao de atualizar do componente pai. */
    desarmar() {
      this.$emit("atualizar", false);
    },
    formatarTitulo: function(titulo) {
      return titulo.length > 12 ? titulo : "";
    },
    /* Verificar se o usuário logado possui uma permissão específica */
    permissao: function(permissao) {
      return util.fncStore.usuarioLogado.permissao(permissao);
    },
    /* Verificar se o usuário logado possui pelo menos uma permissão contida no array */
    arrayPermissao: function(arrayPermissao) {
      return util.fncStore.usuarioLogado.arrayPermissao(arrayPermissao);
    },
    router(router) {
      this.$router.push({ name: router }).catch((err) => err);
    },
  },
};
</script>
