import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

// PROPRIETARIO_USUARIO_PRE_LOGIN: URL_API_SERVICO + "/api/proprietario/usuario/login/pre_login/",
// PROPRIETARIO_USUARIO_LOGIN: URL_API_SERVICO + "/api/proprietario/usuario/login",
// PROPRIETARIO_USUARIO_RECUPERAR_ACESSO: URL_API_SERVICO + "/api/proprietario/usuario/login/recuperar_acesso",
// PROPRIETARIO_USUARIO_RECADASTRAR_SENHA: URL_API_SERVICO + "/api/proprietario/usuario/login/recadastrar_senha",
// PROPRIETARIO_USUARIO_ATIVAR_TODO_USUARIO_VISIVEL: URL_API_SERVICO + "/api/proprietario/usuario/ativar_todo_usuario_visivel",

export default {
  preLogin: email => {
    return http.get("/proprietario/usuario/login/pre_login/" + email);
  },
  login: (idProprietario, email, senha) => {
    return http.get(
      "/proprietario/usuario/login/" +
        idProprietario +
        "/" +
        email +
        "/" +
        senha
    );
  },
  recuperarAcesso: usuarioEmailTelefone => {
    return http.get(
      "/proprietario/usuario/login/recuperar_acesso/" + usuarioEmailTelefone
    );
  },
  recadastrarSenha: parametro => {
    return http.get(
      "/proprietario/usuario/login/recadastrar_senha/" + parametro
    );
  },
  alterarSenha: (idUsuario, novaSenha) => {
    return http.post(
      "/proprietario/usuario/alterar_senha/" + idUsuario + "/" + novaSenha,
      null,
      util.getHeader()
    );
  },
  listarUsuarioAtivoInativo: (usuario, oculto, pagina, limite) => {
    return http.get(
      "/proprietario/usuario/listar_usuario_ativo_inativo/" +
        usuario +
        "/" +
        oculto +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  listarUsuario: (usuario, ativo, oculto, pagina, limite) => {
    return http.get(
      "/proprietario/usuario/listar_usuario/" +
        usuario +
        "/" +
        ativo +
        "/" +
        oculto +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  incluirUsuario: (enviarEmail, item) => {
    return http.post(
      "/proprietario/usuario/incluir_usuario/" + enviarEmail,
      item,
      util.getHeader()
    );
  },
  alterarUsuario: (enviarEmail, item) => {
    return http.put(
      "/proprietario/usuario/alterar_usuario/" + enviarEmail,
      item,
      util.getHeader()
    );
  },
  excluirUsuario: idUsuario => {
    return http.delete(
      "/proprietario/usuario/excluir_usuario/" + idUsuario,
      util.getHeader()
    );
  },
  ativarTodosUsuariosVisiveis: (idProprietario, ativo) => {
    return http.put(
      "/proprietario/usuario/ativar_todo_usuario_visivel/" +
        idProprietario +
        "/" +
        ativo,
      null,
      util.getHeader()
    );
  },
  enviarEmail: idUsuario => {
    return http.get(
      "/proprietario/usuario/enviar_email/" + idUsuario,
      util.getHeader()
    );
  },
  listarUsuarios: function() {
    return http.get("/proprietario/usuario/listar_usuarios/", util.getHeader());
  }
};
