import util from "../util/Util.js";
import { http } from "./Config.js";

export default {
  listarLogUtilizacao: (
    idUnidade,
    idUsuario,
    dataInicial,
    dataFinal,
    metodo,
    conteudo,
    pagina,
    limite
  ) => {
    return http.get(
      "/sistema/log_utilizacao/listar_log_utilizacao/" +
        idUnidade +
        "/" +
        idUsuario +
        "/" +
        dataInicial +
        "/" +
        dataFinal +
        "/" +
        metodo +
        "/" +
        conteudo +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  // Listar todas as unidades de um proprietário
  listarMetodos: function() {
    return http.get(
      "/sistema/log_utilizacao/listar_metodos",
      util.getHeader()
    );
  },
  listarConfiguracaoProprietario: function() {
    return http.get(
      "/sistema/configuracao/listar_configuracao_proprietario",
      util.getHeader()
    );
  },
  alterarConfiguracaoProprietario: item => {
    return http.put(
      "/sistema/configuracao/alterar_configuracao_proprietario",
      item,
      util.getHeader()
    );
  }
};
