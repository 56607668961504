import store from "../index.js";

function incluirLogin(value) {
  store.dispatch("usuarioLogado/incluirLogin", value);
}
function obterLogin() {
  return store.getters["usuarioLogado/obterLogin"];
}
function obterIdProprietario() {
  return this.obterLogin().proprietarioBEA.idProprietario;
}
function obterProprietario() {
  return this.obterLogin().proprietarioBEA.proprietario;
}
function obterUnidade() {
  return this.obterLogin().unidadeBEA.unidade;
}
function obterIdUsuario() {
  return this.obterLogin().idUsuario;
}
function obterUsuario() {
  return this.obterLogin().usuario;
}
function obterNomeUsuario() {
  return this.obterLogin().nomeCompleto;
}
function obterPrimeiroNomeUsuario() {
  return this.obterLogin().nomeCompleto.split(" ")[0];
}
function obterEmail() {
  return this.obterLogin().email;
}
function obterToken() {
  return this.obterLogin().token;
}
// pt
function obterLocaleLanguage() {
  return this.obterLogin().language;
}
// BR
function obterLocaleCountry() {
  return this.obterLogin().country;
}
// pt_BR
function obterLocale() {
  return this.obterLocaleLanguage() + "_" + this.obterLocaleCountry();
}
// Verifica se uma permissao é valida
function permissao(permissao) {
  return this.obterLogin().listPermissao.includes(permissao);
}
// Verifica se pelo menos uma permissao de uma array é valida para o usuário.
// Compara dois arrays e retorna true se um elemento qualquer existe em ambos.
function arrayPermissao(arrayPermissao) {
  return arrayPermissao.some(ai =>
    this.obterLogin().listPermissao.includes(ai)
  );
}
function dataUltimoAcesso() {
  return this.obterLogin().dataUltimoAcesso;
}

// Utilizado pelo LoginUsuario.vue
function incluirDesabilitado(value) {
  store.dispatch("usuarioLogado/incluirDesabilitado", value);
}
function obterDesabilitado() {
  return store.getters["usuarioLogado/obterDesabilitado"];
}

// Substituido por obterLocaleLanguage()
// Retorna o locale abreviado. Ex: pt_BR, retorna pt
// function obterLocaleSimples() {
//   return store.getters["usuarioLogado/obterLocale"].split("-")[0];
// }

export default {
  incluirLogin,
  obterLogin,
  obterIdProprietario,
  obterProprietario,
  obterUnidade,
  obterIdUsuario,
  obterUsuario,
  obterNomeUsuario,
  obterPrimeiroNomeUsuario,
  obterEmail,
  obterToken,
  obterLocaleLanguage,
  obterLocaleCountry,
  obterLocale,
  permissao,
  arrayPermissao,
  dataUltimoAcesso,
  incluirDesabilitado,
  obterDesabilitado
};
