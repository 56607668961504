import UsuarioLogadoFun from "./modules/UsuarioLogadoFun.js";
// import usuarioLogin from "./modules/usuariologin/UsuarioLoginFun.js";
// import unidade from "./modules/UnidadeFun.js";
// import usuario from "./modules/UsuarioFun.js";
// import sistema from "./modules/SistemaFun.js";
// import proprietario from "./modules/ProprietarioFun.js";

export default {
  usuarioLogado: UsuarioLogadoFun
  // usuarioLogin,
  // unidade,
  // usuario,
  // sistema,
  // proprietario
};
