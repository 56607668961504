<template>
  <div>
    <v-chip v-bind="propForcaSenha" text-color="white">
      <v-avatar v-if="propForcaSenha.confirmed">
        <v-icon>check_circle</v-icon>
      </v-avatar>
      {{propForcaSenha.text}}
    </v-chip>
  </div>
</template>

<script>
export default {
  data: () => ({
    propForcaSenha: { color: "grey", text: "...", confirmed: false }
  }),
  mounted () {

  },
  methods: {
    lang: function(parameter) {
      return this.$i18n.t(parameter);
    },
    obterForcaSenha: function(senha) {
      return this.strengthPassword(senha);
    },
    validarForcaSenha: function(senha) {
      let forca = this.obterForcaSenha(senha);
      if (forca >= 3) {
        return true;
      } else {
        return false;
      }
    },
    verificarForcaSenha(senha) {
      let forca = this.obterForcaSenha(senha);
      this.atribuirForcaSenha(forca);
    },
    atribuirForcaSenha(forca) {
      if (forca === 0) {
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = "...";
        this.propForcaSenha.color = "grey";
      } else if (forca === 1) {
        // vermelho - Digitar mais caracteres
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = this.lang("senhaFraca");
        this.propForcaSenha.color = "red";
      } else if (forca === 2) {
        // vermelho - fraca
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = this.lang("senhaFraca");
        this.propForcaSenha.color = "red";
      } else if (forca === 3) {
        // verde - média
        this.propForcaSenha.confirmed = true;
        this.propForcaSenha.text = this.lang("senhaMedia");
        this.propForcaSenha.color = "green";
      } else if (forca === 4) {
        // azul - forte
        this.propForcaSenha.confirmed = true;
        this.propForcaSenha.text = this.lang("senhaForte");
        this.propForcaSenha.color = "blue";
      }
    },
    strengthPassword: function(pwd) {
      // eslint-disable-next-line
      var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
      // eslint-disable-next-line
      var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
      // eslint-disable-next-line
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      var result = "";
      if (pwd.length === 0) {
        result = 0; // Digite uma senha
      } else if (enoughRegex.test(pwd) === false) {
        result = 1; // Digite mais caracteres
      } else if (strongRegex.test(pwd)) {
        result = 4; // Forte
      } else if (mediumRegex.test(pwd)) {
        result = 3; // Médio
      } else {
        result = 2; // Fraco
      }
      return result;
    },
    limpar() {
      this.atribuirForcaSenha(0);
    }
  }
};
</script>

