/* eslint-disable */
export const mixAlerta = {
  created() {},
  data: () => ({}),
  methods: {
    alertInfo(text) {
      this.$swal({
        title: this.lang("aviso") + "!",
        text: text,
        icon: "info",
        confirmButtonText: this.lang("fechar"),
      });
    },

    alertSucess(text) {
      this.$swal({
        title: this.lang("confirmado") + "!",
        text: text,
        icon: "success",
        confirmButtonText: this.lang("fechar"),
      });
    },

    alertSucessToast(text) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: text,
      });
    },

    alertWarning(text) {
      this.$swal({
        title: this.lang("atencao") + "!",
        text: text,
        icon: "warning",
        confirmButtonText: this.lang("fechar"),
      });
    },

    alertWarningToast(text) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "warning",
        title: text,
      });
    },

    alertError(text) {
      this.$swal({
        title: this.lang("algoSaiuErrado"),
        text: text,
        icon: "error",
        confirmButtonText: this.lang("fechar"),
      });
    },

    alertSimple(text) {
      this.$swal(text);
    },

    confirmDelete: function(title, item) {
      return this.$swal({
        title: title,
        text: this.lang("desejaExcluirRegistro") + " " + item,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.lang("sim"),
        cancelButtonText: this.lang("nao"),
        focusConfirm: false,
        reverseButtons: true,
      });
    },

    confirm: function(title, text) {
      return this.$swal({
        title: title,
        text: text,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.lang("sim"),
        cancelButtonText: this.lang("nao"),
        focusConfirm: false,
        reverseButtons: true,
      });
    },
  },
};
