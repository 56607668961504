import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import light from "@/config/Theme.js";

const opts = { theme: { themes: { light } }, icons: { iconfont: "mdi" } };

// const opts = { theme: { disable: true } };

// const opts = { theme: { dark: true }, icons: { iconfont: "mdi" } };

// const opts = {
//   theme: {
//     themes: {
//       light: {
//         primary: "#BBBBBB",
//         secondary: "#b0bec5",
//         accent: "#8c9eff",
//         error: "#b71c1c"
//       }
//     }
//   },
//   icons: { iconfont: "mdi" }
// };

// const opts = {
//   theme: {
//     themes: {
//       light: {
//         primary: "#1976D2",
//         secondary: "#424242",
//         tertiary: "#FFFFFF",
//         accent: "#82B1FF",
//         error: "#FF5252",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FFC107"
//       },
//       dark: {
//         primary: "#1976D2",
//         secondary: "#424242",
//         accent: "#82B1FF",
//         error: "#FF5252",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FFC107"
//       }
//     }
//   },
//   icons: { iconfont: "mdi" }
// };

// const opts = { icons: { iconfont: "mdi" } };

Vue.use(Vuetify);

export default new Vuetify(opts);
