import proprietario from "./Proprietario.js";
import unidade from "./Unidade.js";
import usuario from "./Usuario.js";
import perfil from "./Perfil.js";
import permissao from "./Permissao.js";
import console from "./Console.js";
import sistema from "./Sistema.js";
import veiculo from "./Veiculo.js";

export default {
  proprietario,
  unidade,
  usuario,
  perfil,
  permissao,
  console,
  sistema,
  veiculo,
};
