import { validate } from "vee-validate";

/* Formata a mensagem gerada pelo validador */
function message(errors, field) {
  return errors[0].replace("{field}", field);
}

export default {
  validate,
  message
};
