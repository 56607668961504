const state = {
  login: [],
  desabilitado: false
};
const mutations = {
  INCLUIR_LOGIN: (state, value) => {
    state.login = value;
  },
  INCLUIR_DESABILITADO: (state, value) => {
    state.desabilitado = value;
  }

};
const getters = {
  obterLogin: state => {
    return state.login;
  },
  obterDesabilitado: state => {
    return state.desabilitado;
  }
};
const actions = {
  incluirLogin(context, login) {
    context.commit("INCLUIR_LOGIN", login);
  },
  incluirDesabilitado(context, desabilitado) {
    context.commit("INCLUIR_DESABILITADO", desabilitado);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
