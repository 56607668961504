<template>
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <v-card class="mx-auto elevation-10" max-width="80%">
      <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
        <span>{{ this.lang("login") }}</span>
        <v-spacer />
        <v-avatar
          color="primary lighten-2"
          class="subheading white--text"
          size="24"
          v-text="step"
        ></v-avatar>
      </v-card-title>

      <v-window>
        <v-window-item v-bind:value="1">
          <v-card-text>
            <v-text-field
              name="namIdProprietario"
              ref="refIdProprietario"
              type="number"
              v-model="modIdProprietario"
              v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.chave"
              v-bind:label="lang('id')"
            ></v-text-field>
            <v-text-field
              name="namUsuario"
              ref="refUsuario"
              v-model="modEmail"
              v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.pessoa"
              v-bind:label="lang('usuario')"
            ></v-text-field>
            <v-text-field
              name="namSenha"
              ref="refSenha"
              type="password"
              v-model="modSenha"
              v-bind:label="lang('senha')"
              v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.senha"
            ></v-text-field>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          v-bind:disabled="obterDesabilitado()"
          v-on:click="validarLogin()"
          v-bind="utilImp.cnt.CONFIG.btn.proximo"
          >{{ lang("entrar") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../../../util/Util.js";
import eventBus from "@/util/EventBus.js";

// const PASSO_EMAIL = 1;
// const PASSO_PROPRIETARIO = 2;
// const PASSO_SENHA = 3;
// const PASSO_RECUPERAR_EMAIL = 4;
// const PASSO_RECUPERAR_SENHA = 5;

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    utilImp: util,
    idProprietario: null,
    modIdProprietario: util.cnf.USUARIO_ID_PROPRIETARIO,
    modEmail: util.cnf.USUARIO_EMAIL,
    modSenha: util.cnf.USUARIO_SENHA,
    step: 1,
    regraValidacao: {
      email: "required|email",
      idProprietario: "required",
      senha: "required|min:" + util.cnt.CAMPO.tamanho.minimo.senha,
    },
    mensagemValidacao: {
      email: "",
      proprietario: "",
      senha: "",
      recuperarEmail: "",
      recuperarSenha: "",
    },
    listProprietario: [],
    // classeTitulo:
    //   "title font-weight-regular justify-space-between white--text elevation-0 " +
    //   util.cnt.COR_PADRAO
  }),
  computed: {},
  created() {
    this.iniciar();
  },
  mounted() {
    this.setFocusProprietario();
  },
  methods: {
    iniciar() {},
    async validarLogin() {
      let result = "";
      result = await util.val.validate(
        this.modIdProprietario,
        this.regraValidacao.idProprietario
      );
      if (!result.valid) {
        this.mensagemValidacao.proprietario = this.lang("selecioneUmaEmpresa");
        return;
      }
      result = await util.val.validate(
        this.modEmail,
        this.regraValidacao.email
      );
      if (!result.valid) {
        this.mensagemValidacao.email = util.val.message(
          result.errors,
          this.lang("email")
        );
      }

      result = await util.val.validate(
        this.modSenha,
        this.regraValidacao.senha
      );
      if (!result.valid) {
        this.mensagemValidacao.senha = util.val.message(
          result.errors,
          this.lang("senha")
        );
      }
      this.login();
    },
    login() {
      this.desabilitar(true);
      util.srv.usuario
        .login(this.modIdProprietario, this.modEmail, this.modSenha)
        .then((response) => {
          this.distribuirLogin(response.data);
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuirLogin(login) {
      if (login.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        // Armazenar na store os dados do usuário logado
        util.fncStore.usuarioLogado.incluirLogin(login.result);
        // Internacionalizar a aplicação conforme o locale obtido da API
        this.$i18n.locale = util.fncStore.usuarioLogado.obterLocaleLanguage();
        // Exibir o menu principal
        eventBus.$emit(util.cnt.EVENTBUS.menuPrincipal.exibir);
        // Redirecionar para o componente AvisoDiario.vue
        this.irParaComponenteInicial();
      } else {
        this.alertWarning(this.langResultCode(login.resultCode));
      }
    },
    // recuperarEmail() {
    //   this.desabilitar(true);
    //   this.$refs.refRecuperarEmail.validar();
    // },
    // recuperarSenha() {
    //   this.desabilitar(true);
    //   this.$refs.refRecuperarSenha.validar();
    // },
    irParaComponenteInicial() {
      // this.$router.push('/sistema/aviso_diario')
      this.$router.push({ name: "sistemaInicio" });
    },
    // irParaRecuperarEmail() {
    //   if (this.obterDesabilitado() === false) {
    //     this.step = PASSO_RECUPERAR_EMAIL;
    //     setTimeout(() => {
    //       this.$refs.refRecuperarEmail.setFocusTelefone();
    //     }, util.cnt.CONFIG.timer.focus);
    //   }
    // },
    // irParaRecuperarSenha() {
    //   if (this.obterDesabilitado() === false) {
    //     this.step = PASSO_RECUPERAR_SENHA;
    //     setTimeout(() => {
    //       this.$refs.refRecuperarSenha.setEmail(this.modEmail);
    //     }, util.cnt.CONFIG.timer.focus);
    //   }
    // },
    // selecionarProprietario() {
    //   this.step = PASSO_PROPRIETARIO;
    // },
    limparValidacao() {
      this.mensagemValidacao = { email: "", proprietario: "", senha: "" };
    },
    setFocusProprietario() {
      setTimeout(() => {
        this.$refs.refIdProprietario.focus();
      }, util.cnt.CONFIG.timer.focus);
    },
    setFocusEmail() {
      setTimeout(() => {
        this.$refs.refEmail.focus();
      }, util.cnt.CONFIG.timer.focus);
    },
    setFocusSenha() {
      setTimeout(() => {
        this.$refs.refSenha.focus();
      }, util.cnt.CONFIG.timer.focus);
    },
    // keyUpEmail: function(event) {
    //   this.limparValidacao();
    //   var k = event.key;
    //   if (k === util.cnt.TECLA.enter) {
    //     this.proximoPasso();
    //   }
    // },
    // keyUpSenha: function(event) {
    //   this.limparValidacao();
    //   var k = event.key;
    //   if (k === util.cnt.TECLA.enter) {
    //     this.proximoPasso();
    //   }
    // },
    desabilitar(desabilitado) {
      this.incluirDesabilitado(desabilitado);
    },
    incluirDesabilitado(desabilitado) {
      util.fncStore.usuarioLogado.incluirDesabilitado(desabilitado);
    },
    obterDesabilitado: function() {
      return util.fncStore.usuarioLogado.obterDesabilitado();
    },
  },
};
</script>
