import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

export default {
  listarVeiculo: (placa) => {
    return http.get(
      "/veiculo/listar_veiculo/" +
        placa,
      util.getHeader()
    );
  },
  obterPosicao: (placa) => {
    return http.get(
      "/veiculo/obter_posicao/" +
        placa,
      util.getHeader()
    );
  },
};
