import util from "../util/Util.js";
import { localize } from "vee-validate";

export const mixUtil = {
  created() {
    localize(this.$i18n.t("localize"));
  },
  data: () => ({}),
  methods: {
    verificarPermissao: function(permissao) {
      return util.fncStore.usuarioLogado.permissao(permissao);
    },
    // Internacionalização
    lang: function(parameter) {
      return this.$i18n.t(parameter);
    },
    // Internacionalização de códigos da API
    // langResultCode: function(code) {
    //   return this.lang(util.mapData.mapResultCode.get(code));
    // },
    // Internacionalização de códigos da API
    langResultCode: function(code) {
      return util.mapData.mapResultCode.get(code);
    },
    // Internacionalização com 1 item aninhado
    langParametro: function(parameter, item) {
      return this.$i18n.t(parameter, 10, { 1: item });
    },
    // Internacionalização com 2 itens aninhados
    langParametro2: function(parameter, item1, item2) {
      return this.$i18n.t(parameter, 10, { 1: item1, 2: item2 });
    },
    // Formatar data hora conforme o locale
    formatarDataHora: function(dataHora) {
      return util.fnc.dateFormat(dataHora, this.lang("formatoDataHora"));
    },
    // Formatar data hora conforme o locale
    formatarData: function(data) {
      return util.fnc.dateFormat(data, this.lang("formatoData"));
    },
    // Exibir o Loader em tela cheia
    // Entre a execução do showLoader e hideLoader nenhum dialog ou outro objeto visível deve ser aberto. Isso gera erro.
    showLoader() {
      this.loader = this.$loading.show({
        loader: "spinner", //spinner, dots, bars
        height: 128,
        width: 128,
        color: "cyan",
        backgroundColor: "#333",
        opacity: 0.5,
        canCancel: false
      });
    },
    // Ocultar o Loader
    // Entre a execução do showLoader e hideLoader nenhum dialog ou outro objeto visível deve ser aberto. Isso gera erro.
    hideLoader() {
      if (this.loader !== null) {
        this.loader.hide();
      }
    }
  }
};
