import colors from "vuetify/lib/util/colors";

/* Atributos padrão do vuetify serão sobreescritos quando redefinidos aqui. */
export default {
  // Customização de cores
  // primary: "#1976D2",
  // secondary: "#424242",
  tertiary: colors.grey.darken1, // Botao limpar
  // quaternary: colors.grey.lighten4,
  // quinary: colors.grey.lighten4,
  // senary: colors.grey.lighten4,
  // septenary: colors.grey.lighten4,
  // octonary: colors.grey.lighten4,
  // nonary: colors.grey.lighten4,
  // denary: colors.grey.lighten4,

  // Mestre
  temaMestreBarraSuperiorFundoCor: "#1976D2",
  temaMestreBarraSuperiorTituloTextoCor: "#1976D2",
  temaMestreMenuPrincipalFundoCor: colors.grey.lighten4,
  temaMestreDetalheFundoCor: colors.grey.lighten4,
  temaMestreRodapeFundoCor: "#1976D2",
  // Detalhe
  temaDetalheBarraSuperiorFundoCor: colors.grey.lighten4,
  temaDetalheBarraSuperiorIconeCor: "#1976D2",
  // Janela
  temaJanelaBarraSuperiorCor: "#1976D2",
  temaJanelaRodapeCor: colors.grey.lighten4,
  // Objetos
  temaSelectFundoCor: colors.grey.lighten4,
  temaTextfieldFundoCor: colors.grey.lighten4,
  temaTextareaFundoCor: colors.grey.lighten4

  // Padrão:
  // primary: "#1976D2",
  // secondary: "#424242",
  // accent: "#82B1FF",
  // error: "#FF5252",
  // info: "#2196F3",
  // success: "#4CAF50",
  // warning: "#FFC107"

  // Exemplos
  //   primary: {
  //     base: colors.purple.base,
  //     darken1: colors.purple.darken2
  //   },
  //   secondary: colors.indigo,
};

// Lista completa das chaves substituíveis no objeto de tema:
// base: string
// lighten5: string
// lighten4: string
// lighten3: string
// lighten2: string
// lighten1: string
// darken1: string
// darken2: string
// darken3: string
// darken4: string
