import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

export default {
  listarPerfil: (perfil, oculto, pagina, limite) => {
    return http.get(
      "/proprietario/perfil/listar_perfil/" +
        perfil +
        "/" +
        oculto +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  incluirPerfil: item => {
    return http.post(
      "/proprietario/perfil/incluir_perfil",
      item,
      util.getHeader()
    );
  },
  alterarPerfil: item => {
    return http.put(
      "/proprietario/perfil/alterar_perfil",
      item,
      util.getHeader()
    );
  },
  excluirPerfil: idPerfil => {
    return http.delete(
      "/proprietario/perfil/excluir_perfil/" + idPerfil,
      util.getHeader()
    );
  },
  // Listar todos os perfis de um proprietario
  listarPerfis: oculto => {
    return http.get(
      "/proprietario/perfil/listar_perfis/" + oculto,
      util.getHeader()
    );
  }
};
