import util from "../util/Util.js";
import { http } from "./Config.js"; // {http} entre chaves por ser uma constante

export default {
  listarProprietario: (proprietario, pagina, limite) => {
    return http.get(
      "/proprietario/proprietario/listar_proprietario/" +
        proprietario +
        "/" +
        pagina +
        "/" +
        limite,
      util.getHeader()
    );
  },
  incluirProprietario: item => {
    return http.post(
      "/proprietario/proprietario/incluir_proprietario",
      item,
      util.getHeader()
    );
  },
  alterarProprietario: item => {
    return http.put(
      "/proprietario/proprietario/alterar_proprietario",
      item,
      util.getHeader()
    );
  },
  excluirProprietario: idProprietario => {
    return http.delete(
      "/proprietario/proprietario/excluir_proprietario/" + idProprietario,
      util.getHeader()
    );
  },
  obterProprietario: idProprietario => {
    return http.get(
      "/proprietario/proprietario/obter_proprietario/" + idProprietario,
      util.getHeader()
    );
  },
  gerarId: function() {
    return http.get("/proprietario/proprietario/gerar_id/", util.getHeader());
  }
};
