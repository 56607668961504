import util from "../util/Util.js";
import { http } from "./Config.js";

export default {
  listarPermissao: function() {
    return http.get(
      "/proprietario/permissao/listar_permissao/",
      util.getHeader()
    );
  }
};
