<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="exibirDialogo"
      v-bind:disabled="desabilitado"
      v-on:keydown.esc="fecharDialogoEsc()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.pequeno
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("informeNovaSenha") }}</span>
          <v-divider class="mx-2" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharDialogoEsc()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  name="namNovaSenha"
                  ref="refNovaSenha"
                  type="password"
                  v-model="modNovaSenha"
                  v-bind:label="lang('novaSenha')"
                  v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.senha"
                  v-on:keyup="keyUpNovaSenha"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  name="namConfirmarNovaSenha"
                  ref="refConfirmarNovaSenha"
                  type="password"
                  v-model="modConfirmarNovaSenha"
                  v-bind:label="lang('confirmarNovaSenha')"
                  v-bind:prepend-icon="utilImp.cnt.CONFIG.icon.senha"
                  v-on:keyup="keyUpConfirmarNovaSenha"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <forca-senha ref="refForcaSenha" />
          <v-spacer />
          <v-btn
            v-on:click="fecharDialogoEsc()"
            v-bind:disabled="habilitarBotao === false"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn v-on:click="validar()" v-bind="utilImp.cnt.CONFIG.btn.enviar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.enviar.icone }}</v-icon
            >{{ lang("enviar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import ForcaSenha from "@/views/proprietario/usuario/ForcaSenha.vue";

export default {
  components: { "forca-senha": ForcaSenha },
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    utilImp: util,
    modNovaSenha: "",
    modConfirmarNovaSenha: "",
    regraValidacao: "required|min:" + util.cnt.CAMPO.tamanho.minimo.senha,
    habilitarBotao: true,
    desabilitado: false,
    exibirDialogo: false,
    propForcaSenha: { color: "", text: "", confirmed: false },
    idUsuario: 0
  }),
  mounted() {},
  methods: {
    validar() {
      util.val.validate(this.modNovaSenha, this.regraValidacao).then(result => {
        if (!result.valid) {
          this.alertWarningToast(
            util.val.message(result.errors, this.lang("novaSenha"))
          );
          this.setFocusNovaSenha();
        } else {
          if (this.modNovaSenha === this.modConfirmarNovaSenha) {
            if (this.validarForcaSenha(this.modNovaSenha) === true) {
              this.alterarSenha();
            } else {
              this.alertWarningToast(
                this.lang("senhaInvalida") +
                  ". " +
                  this.lang("digiteSenhaValida")
              );
            }
          } else {
            this.alertWarningToast(this.langCamposIguais());
          }
        }
      });
    },
    alterarSenha() {
      this.habilitar(false);
      util.srv.usuario
        .alterarSenha(this.idUsuario, this.modNovaSenha)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparDialogo();
            this.fecharDialogo();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.habilitar(true);
        });
    },
    setFocusNovaSenha() {
      setTimeout(() => {
        this.$refs.refNovaSenha.focus();
      }, util.cnt.CONFIG.timer.focus);
    },
    keyUpNovaSenha: function(event) {
      this.$refs.refForcaSenha.verificarForcaSenha(this.modNovaSenha);
      var k = event.key;
      if (k === util.cnt.TECLA.enter) {
        this.$refs.refConfirmarNovaSenha.focus();
      }
    },
    keyUpConfirmarNovaSenha: function(event) {
      var k = event.key;
      if (k === util.cnt.TECLA.enter) {
        this.validar();
      }
    },
    langCamposIguais: function() {
      let parameter = "camposDevemSerIguais";
      let item1 = this.lang("novaSenha");
      let item2 = this.lang("confirmarNovaSenha");
      return this.$i18n.t(parameter, 10, { 1: item1, 2: item2 });
    },
    habilitar(habilitar) {
      this.habilitarBotao = habilitar;
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    limparDialogo() {
      this.modNovaSenha = "";
      this.modConfirmarNovaSenha = "";
      // Neste momento o componente ForcaSenha ainda nao foi totalmente criado, por isso o timer.
      setTimeout(() => {
        this.$refs.refForcaSenha.limpar();
      }, util.cnt.CONFIG.timer.focus);
    },

    fecharDialogoEsc() {
      // Fecha somente quando os comandos estão habilitados
      if (!this.desabilitado) {
        this.fecharDialogo();
      }
    },
    fecharDialogo() {
      this.exibirDialogo = false;
    },
    exibir(idUsuario) {
      this.idUsuario = idUsuario;
      this.limparDialogo();
      this.exibirDialogo = true;
      this.setFocusNovaSenha();
    },
    validarForcaSenha: function(senha) {
      return this.$refs.refForcaSenha.validarForcaSenha(senha);
    }
  }
};
</script>
